// ************************************************************
/* 
	## Mobile nav
*/

if ($(window).width() < 1000) {
	var headerHeight = $('.header').outerHeight()
	console.log(headerHeight)
	$(".nav").css('top', headerHeight + 'px');

}

$(".js-nav-toggle").click(function () {
	$(".js-nav-toggle").toggleClass('nav-toggle--active');
	$(".nav").toggleClass('nav--active');
	$("html").toggleClass('fixed');
});

// ************************************************************

// ************************************************************
/* 
	## Slick
*/
function slickInitialize(){
	$('.js-image-slider').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		lazyLoad: 'ondemand'
	});
}

// ************************************************************

// ************************************************************
/* 
	## inViewport
	Adds & removes an active class to the target element when it enters the viewport
*/
function inViewport(targetClass){
	$(targetClass).each(function(){
		var triggerHeight = $(window).innerHeight() / 1.3;
		var divPos = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();
		var activeClass = targetClass.replace('.','') + "--in-viewport";

		if (divPos <= topOfWindow + triggerHeight ) {
			$(this).addClass(activeClass);
		} else {
			$(this).removeClass(activeClass)
		}
	});
}
// ************************************************************

// ************************************************************
/* 
	## inViewport target classes 
	The elements that are effected by the inViewport function
*/
function inViewportTargets(){
	inViewport(".js-count");
	inViewport(".stat-block__stat");
	inViewport(".hero__heading");
	inViewport(".hero__intro");
	inViewport(".alternating");
	inViewport(".call-out");
	inViewport(".intro");
	inViewport(".image");
	inViewport(".stat-block__intro");
	inViewport(".cards__section-heading");
	inViewport(".cards__card");
	inViewport(".company-cards__section-heading");
	inViewport(".company-cards__card");
	inViewport(".categories__section-heading");
	inViewport(".categories__section-copy");
	inViewport(".categories__card");
}
// ************************************************************

// ************************************************************
/* 
	## Number Ticker
*/

function doTicker(el) {
	countTo = el.attr("data-count");
	$({
		countNum: el.text(),
	}).animate({
		countNum: countTo,
	},
	{
		duration: 2000,
		easing: "swing",
		step: function () {
			el.text(Math.floor(this.countNum));
		},
		complete: function () {
			el.text(this.countNum);

			// Thing to add commas to the resulting number
			var textValue = el.text()
			if (!textValue == 0) {
				el.text(textValue.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"))
			}
		},
	});
}

function doTickerReset(el) {
	el.text(0);
}

$(".js-count").each(function() {
	var counted = 0;
	var $this = $(this);
	$(window).scroll(function () {
		if ($this.hasClass("js-count--in-viewport") && counted == 0) {
			doTicker($this);
			counted = 1;
		}

		if (!$this.hasClass("js-count--in-viewport") && counted == 1) {
			doTickerReset($this);
			counted = 0;
		}
	});
});

// ************************************************************



$(document).ready(function() {
	inViewportTargets();
	slickInitialize();
});


$(window).scroll(function() {
	inViewportTargets();
});
